import React from "react";

const FeaturesContent = [
  {
    img: "104",
    title: "Web Application",
    desc: `We leverage modern web technologies to deliver secure, responsive, and high-performance web solutions.`,
  },
  {
    img: "73",
    title: "Mobile Application",
    desc: `We specialize in developing mobile applications
     that engage users and enhance their experiences.`,
  },
  {
    img: "75",
    title: "UI/UX Design",
    desc: `Our UI/UX designers create stunning designs that ensure an engaging and seamless user experience across all devices.`,
  },
  {
    img: "200",
    title: "E-commerce Solutions",
    desc: `We develop secure and user-friendly e-commerce platforms that enable businesses to sell their products and services online.`,
  },
  {
    img: "74",
    title: "Dev-Ops",
    desc: `Our DevOps experts work closely with your development team to establish efficient workflows, 
      implement monitoring and logging systems.`,
  },
  {
    img: "95",
    title: "App Integrations",
    desc: `Empower businesses to enhance their applications 
      by seamlessly connecting them with other systems, platforms, or third-party APIs. `,
  },
  
];
const LcServices = () => {
  return (
    <>
      {FeaturesContent.map((val, i) => (
        <div
          className="col-lg-4 col-sm-6"
          data-aos="fade-up"
          data-aos-duration="1200"
          key={i}
        >
          <div className="block-meta">
            <div className="icon d-flex align-items-end">
              <img src={`images/icon/${val.img}.svg`} alt="icon" />
            </div>
            <h4>{val.title}</h4>
            <p>{val.desc}</p>
          </div>
        </div>
        // .block-meta
      ))}
    </>
  );
};

export default LcServices;
