import React from "react";

const LcFooter = () => {
  const socialContent = [
    {
      icon: "fa-twitter",
      link: "https://twitter.com/lucodeia",
    },
    {
      icon: "fa-linkedin",
      link: "https://www.linkedin.com/company/lucodeia",
    },
  ];
  return (
    <div className="d-lg-flex align-items-center justify-content-between">
      <div className="logo text-center">
        <a href="index.html">
          <img src="images/logo/lucodeia.svg" alt="logo" />
        </a>
      </div>
      <ul className="d-flex justify-content-center social-icon style-none">
        {socialContent.map((val, i) => (
          <li key={i}>
            <a href={val.link} target="_blank" rel="noreferrer">
              <i className={`fa ${val.icon}`}></i>
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default LcFooter;
