import React, { useState } from "react";
import Scrollspy from "react-scrollspy";
import { Link } from "react-router-dom";

const LcHeader = () => {
  // For Mobile  menu
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const [navbar, setNavbar] = useState(false);

  const changeBackground = () => {
    if (window.scrollY >= 90) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  window.addEventListener("scroll", changeBackground);

  return (
    <>
      {/* =============================================
				Theme Main Menu
			==============================================  */}
      <div
        className={
          navbar
            ? "theme-main-menu sticky-menu theme-menu-five fixed"
            : "theme-main-menu sticky-menu theme-menu-five"
        }
      >
        <div className="d-flex align-items-center justify-content-center">
          <div className="logo">
            <img src="images/logo/lucodeia.svg" alt="brand" />
          </div>
          {/* End logo */}

          <nav id="mega-menu-holder" className="navbar navbar-expand-lg">
            <div className="container nav-container">
              <div className="mob-header">
                <button className="toggler-menu" onClick={handleClick}>
                  <div className={click ? "active" : ""}>
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                </button>
              </div>
              {/* End Header */}

              <div
                className="navbar-collapse collapse landing-menu-onepage"
                id="navbarSupportedContent"
              >
                <div className="d-lg-flex justify-content-between align-items-center">
                  <Scrollspy
                    className="navbar-nav  main-side-nav font-gordita"
                    items={[
                      "home",
                      "services",
                      "portfolio",
                      "about",
                      "contact",
                    ]}
                    currentClassName="active"
                    offset={-90}
                  >
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        href="/#home"
                      >
                        Home
                      </a>
                    </li>
                    <li className="nav-item">

                      <a href="/#services" className="nav-link">
                        Services
                      </a>
                    </li>
                    <li className="nav-item">
                      <a href="/#portfolio" className="nav-link">
                        Portfolio
                      </a>
                    </li>
                    <li className="nav-item">
                      <a href="/#about" className="nav-link">
                        About
                      </a>
                    </li>
                    <li className="nav-item">
                      <a href="/#contact" className="nav-link">
                        Contact
                      </a>
                    </li>
                  </Scrollspy>
                </div>
              </div>
            </div>
          </nav>
          {/* End Navbar */}

          <div className="navbar-contact right-widget">
            <ul className="d-flex align-items-center">
              <li>
                <a
                  href="mailto:support@lucodeia.com"
                  className="signIn-action d-flex align-items-center"
                >
                  <span>support@lucodeia.com</span>
                </a>
              </li>
              {/* End login */}
            </ul>
          </div>
          {/* End right-widget */}
        </div>
      </div>
      {/* /.theme-main-menu */}

      {/* Mobile Menu Start */}
      <div className={click ? "mobile-menu  menu-open" : "mobile-menu"}>
        <div className="logo order-md-1">
          <Link to="/doc-landing">
            <img src="images/logo/lucodeia.svg" alt="brand" />
          </Link>
          <div className="fix-icon text-dark" onClick={handleClick}>
            <img src="images/icon/close.svg" alt="icon" />
          </div>
          {/* Mobile Menu close icon */}
        </div>

        <Scrollspy
          className="navbar-nav font-gordita"
          items={[
            "home",
            "services",
            "about",
            "contact",
          ]}
          currentClassName="active"
          offset={-90}
        >
          <li className="nav-item">
            <a href="#home" className="nav-link" onClick={handleClick}>
              Home
            </a>
          </li>
          <li className="nav-item">
            <a href="#services" className="nav-link" onClick={handleClick}>
              Services
            </a>
          </li>
          <li className="nav-item">
            <a href="#about" className="nav-link" onClick={handleClick}>
              About
            </a>
          </li>
          <li className="nav-item">
            <a href="#contact" className="nav-link" onClick={handleClick}>
              Contact
            </a>
          </li>
        </Scrollspy>
      </div>
      {/* Mobile Menu End */}
    </>
  );
};

export default LcHeader;
