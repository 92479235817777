import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Gallery, Item } from "react-photoswipe-gallery";


const AllPortfolio = [
  {
    img: "images/portfolio/lucodeia-news.png",
    title: "Lucodeia News - Wordpress News App",
    meta: "Mobile Development",
    dalayAnimation: "",
    link: "https://codecanyon.net/item/lucodeia-news-flutter-wordpress-news-app/31766444",
    externalLink: true
  },
  {
    img: "images/portfolio/takatf-program-web.png",
    title: "Takatf Program",
    meta: "Web Development",
    dalayAnimation: "",
    link: "#",
    externalLink: false
  },
  {
    img: "images/portfolio/takatf-web.png",
    title: "Takatf",
    meta: "Web Development",
    dalayAnimation: "",
    link: "#",
    externalLink: false
  },
  {
    img: "images/portfolio/tamoh-jobs-app.png",
    title: "Tamoh App",
    meta: "Mobile Development",
    dalayAnimation: "",
    link: "#",
    externalLink: false
  },
  {
    img: "images/portfolio/qatif-school-logo.png",
    title: "Qatif School Logo",
    meta: "Logo Design",
    dalayAnimation: "",
    link: "#",
    externalLink: false
  },
  {
    img: "images/portfolio/alnoor-logo.png",
    title: "Alnoor Logo",
    meta: "Logo Design",
    dalayAnimation: "",
    link: "#",
    externalLink: false
  },
];

const LcPortfolio = () => {
  const settings = {
    dots: false,
    draggable: true,
    arrow: true,
    infinite: true,
    speed: 900,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: false,
    centerPadding: '1300px',
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          dots: true,
        },
      },
    ],
  };



  return (
    <>
      <Gallery>
        <Slider {...settings}>
          {AllPortfolio.map((val, i) => (
            <div
              className="item mix"
              key={i}
              data-aos="fade-right"
              data-aos-delay={val.dalayAnimation}
            >
              <div className="portfolio-block-two position-relative">
                <div className="d-flex align-vals-center justify-content-center">
                  <img
                    src={val.img}
                    alt={val.meta}
                    className="w-100 h-100 tran4s img-meta"
                  />
                  <Item
                    original={val.img}
                    thumbnail={val.img}
                    width={635}
                    height={544}
                  >
                    {({ ref, open }) => (
                      <div
                        className="fancybox"
                        role="button"
                        ref={ref}
                        onClick={open}
                      >
                        <i
                          className="fa fa-arrows-alt"
                          aria-hidden="true"
                        ></i>
                      </div>
                    )}
                  </Item>
                </div>
                <div className="hover-content">
                  <h3>
                    {<a href={val.link} target={val.externalLink ? "_blank" : "_self"} rel="noreferrer">{val.title}</a>}
                  </h3>
                  <div className="tag">{val.meta}</div>
                </div>
                {/* /.hover-content */}
              </div>
              {/* /.portfolio-block-two */}
            </div>
          ))}
        </Slider>
      </Gallery>
    </>
  );
};

export default LcPortfolio;
