import React from "react";
import { Helmet } from "react-helmet";
import LcHeader from "../components/LcHeader";
import LcHeroBanner from "../components/LcHeroBanner";
import LcServices from "../components/LcServices";
import LcFooter from "../components/LcFooter";
import LcPortfolio from "../components/LcPortfolio";

const LcHomeView = () => {
  return (
    <div className="main-page-wrapper font-gordita">
      <Helmet>
        <title>
          {" "}
          Lucodeia - Building Solutions, Inspiring Possibilities
        </title>
      </Helmet>
      {/* End Page SEO Content */}

      <LcHeader />
      {/* End Header */}

      {/* 	=============================================
				Hero Banner
			==============================================  */}
      <div id="home">
        <LcHeroBanner />
      </div>

      {/* =====================================================
           Services
        ===================================================== */}
      <div
        className="fancy-feature-seventeen lg-container pt-150 md-pt-90"
        id="services"
      >
        <div className="container">
          <div className="block-style-eighteen mt-40 pt-40 md-mt-50 text-center mb-130 md-mb-90">
            <div className="row align-items-center">
              <div className="text-wrapper">
                <h6>Our services</h6>
                <h3 className="title">
                  What <span>we do</span> for you
                </h3>
              </div>
              {/* /.text-wrapper */}
            </div>
          </div>
        </div>
        {/* End .container */}

        <div className="container">
          <div className="bottom-border pb-50">
            <div className="row">
              <LcServices />
            </div>
          </div>
        </div>
      </div>
      {/* <!-- /.fancy-feature-seventeen --> */}

      {/*=====================================================
			Portfolio
			===================================================== */}
      <div className="fancy-feature-sixteen pt-180 md-pt-100" id="portfolio">
        <div className="container">
          <div className="title-style-seven">
            <h2>
              Our <span>Portfolio</span>
            </h2>
          </div>
          {/*  /.title-style-seven */}
        </div>

        <div className="portfolioSliderOne arrow-top-right pt-120 md-pt-70">
          <LcPortfolio />
        </div>
      </div>
      {/* /.fancy-feature-sixteen */}

      {/* 	=============================================
				About
			==============================================  */}
      <div className="fancy-feature-sixteen" id="about">
        <div className="container">

          <div className="block-style-eighteen mt-100 pt-50 md-mt-80">
            <div className="row align-items-center">
              <div
                className="col-lg-12 order-lg-last"
                data-aos="fade-left"
                data-aos-duration="1200"
              >
                <div className="text-wrapper">
                  <h6>About Us</h6>
                  <h3 className="title">
                    We’re on a <span>mission</span> to evolve creativity for the better
                  </h3>
                  <p>
                    We are a dynamic startup specializing in mobile and web application development. With a passion for innovation and a dedication to delivering exceptional user experiences, we strive to transform ideas into reality. Our talented team of developers, designers, and strategists work collaboratively to create cutting-edge solutions that meet the unique needs of our clients. From concept to deployment, we embrace the latest technologies and industry best practices to ensure the highest quality and performance of our applications.
                  </p>
                </div>
                {/* /.text-wrapper */}
              </div>
            </div>
          </div>
          {/* /.block-style-eighteen */}

        </div>
      </div>
      {/*  /.fancy-feature-sixteen */}

      {/* 	=====================================================
				Contact
			===================================================== */}
      <div className="fancy-short-banner-eight mt-170 md-mt-80" id="contact">
        <div className="container">
          <div className="row">
            <div
              className="col-xl-8 col-lg-11 m-auto"
              data-aos="fade-up"
              data-aos-duration="1200"
            >
              <div className="title-style-seven text-center">
                <h2>
                  Let’s <span>Work</span> Together
                </h2>
                <p>Start your project with us</p>
              </div>
              {/*  /.title-style-six */}
            </div>
          </div>
          <div className="text-center" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="150">
            <a href="mailto:support@lucodeia.com" className="theme-btn-fourteen mt-30">
              Contact Us
            </a>
          </div>
        </div>
        {/*  /.container */}
        <img
          src="images/shape/171.svg"
          alt="shape"
          className="shapes shape-one"
        />
        <img
          src="images/shape/172.svg"
          alt="shape"
          className="shapes shape-two"
        />
      </div>
      {/*  /.fancy-short-banner-eight */}

      {/* <!--
        =====================================================
            Footer Style Nine
        =====================================================
        --> */}
      <div className="theme-footer-nine lg-container">
        <div className="container">
          <LcFooter />
        </div>
      </div>
    </div>
  );
};

export default LcHomeView;
