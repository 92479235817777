import React from "react";

const LcHeroBanner = () => {
  return (
    <div className="hero-banner-seven lg-container">
      <div className="container">
        <div className="row">
          <div className="col-lg-8">
            <h1 className="hero-heading">
              <span>Empowering</span> Tech Solutions
            </h1>
            <p className="hero-sub-heading">
              We deliver best problem-solving solution for our client and provide finest finishing product.
            </p>
          </div>
        </div>
        {/* End .row */}
      </div>
      {/* End .container */}

    </div>
  );
};

export default LcHeroBanner;
