import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import AllRoutes from "./router/AllRoutes";
import AOS from "aos";
import "aos/dist/aos.css";
import "photoswipe/dist/photoswipe.css";
import "bootstrap/dist/js/bootstrap";

const App = () => {
  useEffect(() => {
    AOS.init({
      duration: 1200,
      once: true,
    });
  }, []);
  window.addEventListener("load", AOS.refresh);

  return (
    <>
      <Helmet>
        <title>Lucodeia - Building Solutions, Inspiring Possibilities</title>
        <meta property="og:site_name" content="lucodeia" />
        <meta
          property="og:url"
          content="https://www.lucodeia.com"
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="Lucodeia - Building Solutions, Inspiring Possibilities"
        />
        <meta
          name="keywords"
          content="agency, bootstrap 5, business, flutter, wordpress, react, creative, fullpage, minimal, modern, multipurpose, parallax, personal, photography, portfolio, showcase, Digital marketing agency, Digital marketing company, Digital marketing services, sass, software company"
        />
        <meta
          name="description"
          content="From concept to deployment, we embrace the latest technologies and industry best practices to ensure the highest quality and performance of our applications"
        />
        <meta name="description" content="a dynamic startup specializing in mobile and web application development" />
      </Helmet>
      {/* End Seo Helmt */}

      <AllRoutes />
    </>
  );
};

export default App;
